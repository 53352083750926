$centralContainerMinWidth: 350px; // 750px

$topPartHeight: 61px;
$verticalSideBarExpandedWidth: 250px;
$verticalSideBarCollapsedWidth: 90px;

:root {
    --lce-template-sheldon-top-part-height: #{$topPartHeight};
    --lce-template-sheldon-vertical-sidebar-expanded-width: #{$verticalSideBarExpandedWidth};
    --lce-template-sheldon-vertical-sidebar-collapsed-width: #{$verticalSideBarCollapsedWidth};
}

// -------------------------------------------------------------------------------------------------------------------
// === * ===
// -------------------------------------------------------------------------------------------------------------------

.lce-context-menu {
    width: 100%;

    .lce-background-icon-parameters {
        &:after {
            top: 75px;
            right: -75px;
            left: unset;
        }
    }
}


.lce-template-sheldon-user-menu-overlay {
    top: 55px !important;
}

.lce-template-sheldon {
    position: relative;
    background: var(--xs-color-tertiary);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    &.lce-template-sheldon-vertical-sidebar-expanded {

        .lce-template-sheldon-left-part {
            width: $verticalSideBarExpandedWidth;
        }

        .lce-template-sheldon-right-part {
            width: -webkit-calc(100% - #{$verticalSideBarExpandedWidth});
            width: -moz-calc(100% - #{$verticalSideBarExpandedWidth});
            width: calc(100% - #{$verticalSideBarExpandedWidth});
            left: $verticalSideBarExpandedWidth;
        }
    }

    &.lce-template-sheldon-vertical-sidebar-collapsed {

        .lce-template-sheldon-left-part {
            width: $verticalSideBarCollapsedWidth;
        }

        .lce-template-sheldon-right-part {
            width: -webkit-calc(100% - #{$verticalSideBarCollapsedWidth});
            width: -moz-calc(100% - #{$verticalSideBarCollapsedWidth});
            width: calc(100% - #{$verticalSideBarCollapsedWidth});
            left: $verticalSideBarCollapsedWidth;
        }
    }

    .lce-template-sheldon-left-part {
        height: 100%;
        background: var(--xs-background-color);
        position: relative;
        border-right: 1px solid var(--xs-color-discrete);
    }

    .lce-template-sheldon-right-part {
        position: absolute;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .lce-template-sheldon-top-part {
            //border: 1px solid cyan;
            padding: 5px 30px;
            background: var(--xs-background-color);
            border-bottom: 1px solid var(--xs-color-light);
            height: $topPartHeight;

            .lce-template-sheldon-header {
                display: flex;
                align-items: center;

                lce-global-search {
                    .lce-global-search-icon {
                        position: absolute;
                        left: 8px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: var(--xs-color-light);
                    }

                    .p-autocomplete-input.p-inputtext {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        height: 30px;
                        font-size: 13px;
                        padding-left: 30px;
                    }

                    .p-autocomplete-item:hover {
                        background: transparent !important;
                    }
                }
            }
        }

        .lce-template-sheldon-center-part {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding-top: 15px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 15px;
            overflow-y: auto;
            background: var(--xs-color-tertiary);
            overflow-y: auto;

            .lce-template-sheldon-center-part-inner {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                min-width: $centralContainerMinWidth;

                .lce-template-sheldon-breadcrumb {
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .lce-template-sheldon-router-container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    border-radius: var(--xs-border-radius-default);
                    background: var(--xs-background-color);
                    padding: 20px;

                    .lce-template-sheldon-router {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .lce-template-sheldon-router-header {
                            //border: 1px solid red;
                            display: flex;
                            flex-direction: row;
                            align-items: end;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            min-height: 30px;

                            .lce-template-sheldon-router-title-container {
                                //border: 1px solid cyan;
                                display: flex;
                                flex-direction: column;

                                .lce-template-sheldon-router-title {
                                    display: block;
                                    font-size: var(--xs-font-size-large);
                                    font-weight: 400;
                                }

                                .lce-template-sheldon-router-sub-title {
                                    display: block;
                                    font-size: var(--xs-font-size-small);
                                    margin-top: 1px;
                                }
                            }

                            .lce-template-sheldon-router-action-container {
                                //border: 1px solid violet;
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                xs-button {
                                    margin-left: 50px;
                                }
                            }
                        }

                        .lce-template-sheldon-router-content {
                            //border: 1px solid green;
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }

        .lce-template-sheldon-bottom-part {
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 30px;
            padding-right: 30px;
            display: flex;
            align-items: center;
            font-size: 10px;
        }
    }
}
