body {
    overflow: auto;
    background: var(--xs-color-tertiary);
}

.lce-template-stn {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 15px 25px 0px;
    background: var(--xs-color-tertiary);

    .lce-template-stn-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .lce-template-stn-content {
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 40px;
        position: relative;
        background: var(--xs-background-color);
        border-radius: 12px;
        padding-top: 30px;

        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .lce-template-stn-footer {
        padding: 8px 10px;
    }
}
