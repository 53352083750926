.lce-brand {
    padding: 0 10px;
    background: #879fab;
    color: white;
    border-radius: 7px;
    letter-spacing: 4px;
    font-family: monospace;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
}